import React from "react"
import * as Icon from "react-feather"
import { graphql } from "gatsby"

import CaseDetail from "../../components/Shared/casedetails"

const seoTitle = "Data Management and Governance"
const pageTitle = "Data Management and Governance"
const subTitle = "Data Science"
const para =
  "Atyeti takes a ‘Data First’ strategy, because no amount of downstream analytics can replace solid data management or mitigate risk from poor governance. Data Engineers work closely with Business Analysts and Data Analysts to define appropriate quantification parameters and data remediation policies. Governance is designed-in at the infrastructure architecture, ensuring end-to-end comprehensive coverage before any data is even received."
const blockquote = `"A PRINCIPLED, COMPREHENSIVE APPROACH."`

const cardItems = [
  {
    id: 1,
    heading: `Storage and Retrieval:`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          <strong>Format Design:  </strong>Data-centric design for Unstructured, Document,
          Relational, Timeseries, and Graph databases
        </li>
        <li>
          <Icon.Square />
          <strong>Analytic Enablement:  </strong>MPP and Scalable Lakehouse design targeting
          analytic workflows
        </li>
        <li>
          <Icon.Square />
          <strong>Service Optimization:  </strong>Optimization of data, schema and database for
          data serving to applications
        </li>
        <li>
          <Icon.Square />
          <strong>Archive Support:  </strong>Cold storage and retention policies
        </li>
      </>
    ),
  },
  {
    id: 2,
    heading: `Quality`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          <strong>Consistency:  </strong>Consistency of data with related sets
        </li>
        <li>
          <Icon.Square />
          <strong>Timeliness:  </strong>Reliable SLAs of ingestion and analytics
        </li>
        <li>
          <Icon.Square />
          <strong>Validity:  </strong>Model based validity assessments
        </li>
        <li>
          <Icon.Square />
          <strong>Completeness:  </strong>Missing data, out of order records
        </li>
        <li>
          <Icon.Square />
          <strong>Uniqueness:  </strong>De-duplication and redundancy elimination
        </li>
      </>
    ),
  },
  {
    id: 3,
    heading: `Security`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          <strong>Legality:  </strong>Regulatory compliance
        </li>
        <li>
          <Icon.Square />
          <strong>Confidentiality:  </strong>Access control, data masking
        </li>
        <li>
          <Icon.Square />
          <strong>Regional Control:  </strong>Geographic fencing
        </li>
        <li>
          <Icon.Square />
          <strong> Durability:  </strong>Redundancy, versioning, source retention
        </li>
        <li>
          <Icon.Square />
          <strong>Uniqueness:  </strong>De-duplication and redundancy elimination
        </li>
      </>
    ),
  },
  {
    id: 4,
    heading: `Transparency`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          <strong>Accessibility:  </strong>Easy visibility into quality metrics
        </li>
        <li>
          <Icon.Square />
          <strong>Accountability:  </strong>Auditability, fault tracing
        </li>
        <li>
          <Icon.Square />
          <strong>Discovery:  </strong>Searchable, automated data dictionaries
        </li>
      </>
    ),
  },
  {
    id: 4,
    heading: `Provenance`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          <strong>
            {" "}
            Origination:  </strong>Meta regarding sourcing information{" "}
         {" "}
        </li>
        <li>
          <Icon.Square />
          <strong>
            Chain of Custody:  </strong>Meta tracking of historic ownership and storage
        </li>
        <li>
          <Icon.Square />
          <strong>
            Analytic Record:  </strong>Complete production and transformation legacy
            tracking
     {" "}
        </li>
      </>
    ),
  },
  {
    id: 4,
    heading: `Scalability`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          <strong>Data Volume:  </strong>Scalable data parallelization
        </li>
        <li>
          <Icon.Square />
          <strong>Data Frequency:  </strong>Reconfigurable batch, minibatch and stream analytics
          without recoding
        </li>
        <li>
          <Icon.Square />
          <strong>Computational Complexity:  </strong>Micro services and task parallelization{" "}
        </li>
      </>
    ),
  },
]
const BlogDetails = ({ data }) => (
  <CaseDetail
    seoTitle={seoTitle}
    pageTitle={pageTitle}
    cardItems={cardItems}
    subTitle={subTitle}
    para={para}
    blockquote={blockquote}
    images={data}
  />
)
export const query = graphql`
  query DataManagementAndGovernanceQuery {
    casedetailsJson(slug: { eq: "data-management-and-governance" }) {
      images {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
    }
  }
`
export default BlogDetails
